/*eslint no-console: 0 */
/*eslint vue/no-unused-components: 0 */

import Multiselect from "./components/Multiselect";
import FavoriteIcon from "./components/FavoriteIcon";

export default {
  name: "arcgiraff-artistliste",
  data() {
    return {
      devServer: false,
      isLoaded: false,
      artistsData: [],
      originalArtistsData: [],
      selected: [],
      favorites: [],
      filterOptions: [],
      sortingType: "lineup", // lineup, alpha or date
      siteUrl: "https://varangerfestivalen.no",
      jsonArtistsPath: "",
    };
  },
  components: {
    Multiselect,
    FavoriteIcon,
  },
  methods: {
    onFilterChange() {
      console.log("Filter change: selected = ", this.selected);
      this.artistsData = this.filterOnDay(this.originalArtistsData, "favoritt");
      this.artistsData = this.filterOnDay(this.artistsData, "date");
      this.artistsData = this.filterOnDay(this.artistsData, "scene");
    },
    filterOnDay(arrayToFilter, filterType) {
      var selectedDays = this.selected.filter((a) => {
        return a.type == filterType;
      });
      var selectedDaysStringArray = [];
      selectedDays.forEach((dayObject) =>
        selectedDaysStringArray.push(dayObject.data)
      );
      if (selectedDays.length == 0) {
        return arrayToFilter;
      } else {
        // console.log("selected days = ", selectedDays);
        // console.log("selected days array = ", selectedDaysStringArray);
        switch (filterType) {
          case "date":
            return arrayToFilter.filter((a) => {
              return selectedDaysStringArray.indexOf(a.acf.dato) != -1;
            });
          case "scene":
            return arrayToFilter.filter((a) => {
              return selectedDaysStringArray.indexOf(a.acf.scene) != -1;
            });
          case "favoritt":
            return arrayToFilter.filter((a) => {
              return this.favorites.indexOf(a.id) != -1;
            });
          default:
            return arrayToFilter;
        }
        // return [];
      }
    },
    gotoLink(linkURL, blank = "") {
      // console.log("gotoLink: link = " + linkURL + ", blank = " + blank);
      if (blank == "") {
        window.location.href = linkURL;
      } else {
        window.open(linkURL);
      }
    },
    parseDate(str) {
      var D = this.getCorrectDate(str);

      var newDateString = D.toLocaleDateString("nb-NO", {
        weekday: "short",
      }).substr(0, 3);
      newDateString += " " + D.toLocaleDateString("nb-NO", { day: "numeric" });
      newDateString += " " + D.toLocaleDateString("nb-NO", { month: "short" });

      return newDateString;
    },
    getCorrectDate(str) {
      if (str == null) {
        console.log("");
        return new Date(0);
      }

      if (this.devServer || str.substr(0, 5) === "/") {
        var y = str.substr(0, 4),
          m = str.substr(4, 2) - 1,
          d = str.substr(6, 2);
        return new Date(y, m, d);
      } else {
        var dateArray = str.split("/");
        return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
      }
    },
    addTimeToDate(dateObject, timeString) {
      if (timeString == null) return dateObject;

      var hours = parseInt(timeString.substr(0, 2));
      var mins = parseInt(timeString.substr(3, 2));
      dateObject.setHours(hours, mins);

      return dateObject;
    },
    sortEventStartTime(time) {
      console.log("App.sortEventStartTime");
      var sortStartTimeArr = time.split(":");

      var hours = parseInt(sortStartTimeArr[0]) - 4;
      if (hours < 0) {
        hours += 24;
      }
      var hoursString = hours.toString();
      if (hours < 10) {
        hoursString = "0" + hoursString;
      }

      return hoursString + ":" + sortStartTimeArr[1];
    },
    /**
     * getActiveDatesAndScenes
     *
     * Returns dates with events in the format
     * { name: <day name>, data: <date or scene string>, type: <string with type> }
     *
     * Available types are "date" and "scene"
     *
     * example
     * { name: "monday", data: "06/08/2020", type: "date" }
     */
    getActiveDatesAndScenes() {
      var scenes = [];

      // dates contain objects in the format to return
      var dates = [];

      // jsonDates contain stringified objects of dates and is used to
      // check if the date already exists in the array
      var jsonDates = [];
      var jsonScenes = [];

      for (var i = 0; i < this.originalArtistsData.length; i++) {
        let date = this.getCorrectDate(this.originalArtistsData[i].acf.dato);
        const dayName = date.toLocaleDateString("nb-NO", { weekday: "long" });
        var dateObject = {
          name: dayName,
          data: this.originalArtistsData[i].acf.dato,
          type: "date",
        };
        if (jsonDates.indexOf(JSON.stringify(dateObject)) == -1) {
          dates.push(dateObject);
          jsonDates.push(JSON.stringify(dateObject));
        }

        const sceneName = this.originalArtistsData[i].acf.scene;
        const sceneObject = { name: sceneName, data: sceneName, type: "scene" };
        if (jsonScenes.indexOf(JSON.stringify(sceneObject)) == -1) {
          jsonScenes.push(JSON.stringify(sceneObject));
          scenes.push(sceneObject);
        }
      }

      dates = dates.sort((a, b) => (a.data > b.data ? 1 : -1));
      scenes = scenes.sort((a, b) => (a.data > b.data ? 1 : -1));
      // console.log("dates = ", dates);
      // console.log("scenes = ", scenes);
      return dates.concat(...scenes);
    },
    filterArray(array, filters) {
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // validates all filter criteria
        return filterKeys.every((key) => {
          // ignores non-function predicates
          if (typeof filters[key] !== "function") return true;
          return filters[key](item[key]);
        });
      });
    },
    onToggleFavorite(artist) {
      const favIndex = this.favorites.indexOf(artist.id);
      if (favIndex == -1) {
        this.favorites.push(artist.id);
      } else {
        this.favorites.splice(favIndex, 1);
      }
      this.setCookie("va_favorites", this.favorites.toString(), 365);
      this.onFilterChange();
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  computed: {
    hrClass() {
      if (this.sortingType == "lineup") {
        return { "hr-lineup": true };
      } else if (this.sortingType == "alpha") {
        return { "hr-alpha": true };
      } else if (this.sortingType == "date") {
        return { "hr-date": true };
      }

      return {};
    },
    artists() {
      var sortedArtists = JSON.parse(JSON.stringify(this.artistsData));
      switch (this.sortingType) {
        case "lineup":
          break;
        case "alpha":
          sortedArtists.sort((a, b) => {
            var A = a.title.rendered;
            var B = b.title.rendered;

            if (A < B) return -1;
            if (B > A) return 1;
            return 0;
          });
          break;
        case "date":
          sortedArtists.sort((a, b) => {
            console.log("json date = " + a.acf.dato);
            var dateA = this.getCorrectDate(a.acf.dato);
            console.log("date = ", dateA);
            var dateASortStartTime = this.sortEventStartTime(
              a.acf.start_tidspunkt
            );
            dateA = this.addTimeToDate(dateA, dateASortStartTime);

            console.log("date with starttime = ", dateA);
            var dateB = this.getCorrectDate(b.acf.dato);
            var dateBSortSStartTime = this.sortEventStartTime(
              b.acf.start_tidspunkt
            );
            dateB = this.addTimeToDate(dateB, dateBSortSStartTime);
            return dateA - dateB;
          });
          break;
        default:
      }

      return sortedArtists;
    },
  },
  mounted() {
    this.filterOptions = [
      { name: "Mine favoritter", data: "favoritter", type: "favoritt" },
    ];

    this.favorites = this.getCookie("va_favorites")
      .split(",")
      .map(Number);

    //-- API path  setup
    var wpApiPath =
      "/wp-json/wp/v2/artister?orderby=menu_order&order=asc&per_page=100";

    console.log("-----------");
    console.log("url = " + window.location.origin);

    var currentUrl = this.siteUrl;
    if (window.location.hostname != "localhost") {
      currentUrl = window.location.origin;
      if (currentUrl === "https://dev.arcgiraff.no") {
        currentUrl = window.location.href;
      }
    }

    console.log("currentUrl = " + currentUrl);

    //-- Download data from API
    this.$http //axios
      .get(currentUrl + this.jsonArtistsPath + wpApiPath)
      .then((response) => {
        this.originalArtistsData = response.data;
        this.originalArtistsData.forEach((item) => (item.favorite = false));
        this.artistsData = JSON.parse(JSON.stringify(this.originalArtistsData));
        const datesAndScenes = this.getActiveDatesAndScenes();
        // console.log("dates and scenes = ", datesAndScenes);
        this.filterOptions.push(...datesAndScenes);
        // console.log("ag-artist-list: list = ", this.artistsData);
        this.isLoaded = true;
      })
      .catch((error) => console.log(error));
  },
};
