<template>
  <button class="favorite-icon" @mouseover="hover = true" @mouseleave="hover = false" @click="signalToggle">
    <svg
      version="1.1"
      id="Icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 22 20"
      style="enable-background:new 0 0 22 20;"
      xml:space="preserve"
    >
      <filter filterUnits="objectBoundingBox" height="230%" id="a" width="208.3%" x="-54.2%" y="-65%">
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1"></feMorphology>
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
        <feMorphology in="SourceAlpha" operator="erode" radius="1" result="shadowInner"></feMorphology>
        <feOffset in="shadowInner" result="shadowInner"></feOffset>
        <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"></feGaussianBlur>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
      </filter>
      <g v-if="hover" id="mouseoverShadow" class="st0">
        <path
          id="b_1_"
          class="st1"
          d="M11,15L5.9,9.9C5,9,4.7,7.7,5.3,6.6C5.8,5.8,6.6,5.2,7.5,5c1-0.2,1.9,0.1,2.6,0.8L11,6.6l0.8-0.8
		c0.7-0.7,1.7-1,2.6-0.8c1,0.1,1.8,0.7,2.2,1.6C17.3,7.7,17,9,16.1,9.9L11,15z"
        />
      </g>
      <path
        id="icon"
        class="st2"
        d="M11,15L5.9,9.9C5,9,4.7,7.7,5.3,6.6C5.8,5.8,6.6,5.2,7.5,5c1-0.2,1.9,0.1,2.6,0.8L11,6.6l0.8-0.8
	c0.7-0.7,1.7-1,2.6-0.8c1,0.1,1.8,0.7,2.2,1.6C17.3,7.7,17,9,16.1,9.9L11,15z"
      />
      <path
        v-if="selected"
        id="selected"
        class="st3"
        d="M11,15L5.9,9.9C5,9,4.7,7.7,5.3,6.6C5.8,5.8,6.6,5.2,7.5,5c1-0.2,1.9,0.1,2.6,0.8L11,6.6l0.8-0.8
	c0.7-0.7,1.7-1,2.6-0.8c1,0.1,1.8,0.7,2.2,1.6C17.3,7.7,17,9,16.1,9.9L11,15z"
      />
    </svg>
  </button>
</template>
<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hover: false
    };
  },
  methods: {
    signalToggle() {
      this.$emit("toggleFavorite");
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  width: 30px;
  /* height: 12.5px; */
}
.st0 {
  filter: url(#a);
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.st2 {
  fill: none;
  stroke: #f5a623;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st3 {
  // display: none;
  fill: #f5a623;
  stroke: #f5a623;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
